import { mapMutations } from 'vuex'

export default {
  name: 'app',
  created() {
    console.log('app started')
    console.log('window.origin', window.location.origin)
    console.log('window.origin', window.location.pathname)
    console.log('window.origin', window.location.hostname)

    if (performance.navigation.type) {
      const user = JSON.parse(sessionStorage.getItem('user'))
      this.updateLoggedInUser(user)
      console.log('refreshed user updated', user.id)
    }
  },
  methods: {
    ...mapMutations(['updateLoggedInUser']),
  },
  // beforeunloadFn(e) {
  //   console.log('beforeunload called')
  //   const user = JSON.parse(sessionStorage.getItem('user'))
  //   this.updateLoggedInUser(user)
  //   console.log('refreshed user updated', user.id)
  // },
}
