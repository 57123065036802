// Copywrite Justin Roseman 2021

export default {
  name: 'article-feed',
  props: [],
  data() {
    return {
      feedArticles: [
        {
          id: '89047u012',
          image: 'article1',
          title: 'How To Cope',
          description: 'Learn to cope with the loss of a loved one.',
          article: '',
          author: 'Jill Tomston',
        },
        {
          id: '89047u013',
          image: 'article2',
          title: 'Begin To Smile Again',
          description: 'Learn to cope with the loss of a loved one.',
          article: '',
          author: 'Brandon Wethers',
        },
      ],
    }
  },
  async created() {
    // const posts = await this.fetchPosts('76ded346-3b76-11ec-8d3d-0242ac130003');
    // this.feedPosts = posts.slice(0, 3);
  },
  methods: {},
  computed: {},
}
