// import { commentAPI } from '@/apis/apis';

export default {
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
};
