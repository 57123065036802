// Copywrite Justin Roseman 2021
import { storageAPI } from '../../apis/apis'

export default {
  state: {
    bucket: null,
    userProfileImg: null,
  },
  getters: {
    getUserProfileImg: state => state.getUserProfileImg,
  },
  mutations: {
    setUserProfileImg(state, userProfileImg) {
      state.userProfileImg = userProfileImg
    },
    updateBucket(state, bucket) {
      state.bucket = bucket
    },
  },
  actions: {
    uploadProfileImage({ commit, rootGetters }, payload) {
      storageAPI.defaults.headers.common['Authorization'] = `Bearer ${rootGetters.getAccessToken}`
      return new Promise((resolve, reject) => {
        storageAPI
          .post(`/upload-profile-img/${payload.id}/${payload.type}`, payload.formData, {
            onUploadProgress: uploadEvent => console.log('Upload Progress: ' + Math.round((uploadEvent.loaded / uploadEvent.total) * 100)),
          })
          .then(res => {
            commit('setUserProfileImg', res.data)
            console.log('uploaded profile img', res.data)
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
    uploadPost({ commit, rootGetters }, payload) {
      storageAPI.defaults.headers.common['Authorization'] = `Bearer ${rootGetters.getAccessToken}`
      return new Promise((resolve, reject) => {
        storageAPI
          .post(`/create-post/${payload.id}/${payload.fileType}/${payload.description}/${payload.createdBy}/${payload.status}`, payload.formData, {
            onUploadProgress: uploadEvent => console.log('Upload Progress: ' + Math.round((uploadEvent.loaded / uploadEvent.total) * 100)),
          })
          .then(res => {
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
