// Copywrite Justin Roseman 2021
import { CometChatUI } from '../../../../cometchat-pro-vue-ui-kit/CometChatWorkspace/src'
import { authAPI } from '../../../apis/apis'
export default {
  name: 'control-messages',
  data() {
    return {}
  },
  components: {
    CometChatUI,
  },
  mounted() {},
  created() {},
  methods: {
    signout() {
      authAPI.post('/signout').then(res => {
        console.log('signed out')
        this.$router.push({ name: 'landingPage' })
      })
      let chatId = document.getElementById('cometchat')
      chatId.remove()
      let chatWidgetId = document.getElementById('cometchat__widget')
      chatWidgetId.remove()
    },
  },
  computed: {},
}
