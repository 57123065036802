// Copywrite Justin Roseman 2021
import { mapActions, mapGetters } from 'vuex'
import { eventAPI, postAPI, vaultAPI } from '@/apis/apis'
import Alert from '@/components/alert'
import CreateVault from '../../../components/create-vault/index.vue'
import PostReview from '../../../components/post-review/index.vue'
import VaultList from '../../../components/vault-list/index.vue'

export default {
  name: 'control-vaults',
  components: {
    Alert,
    CreateVault,
    PostReview,
    VaultList,
  },
  data() {
    return {
      disabled: true,
      isDrawerOpen: false,
      direction: 'rtl',
      followedVaults: [],
      managedVaults: [],
      managedVaultsWithBells: [],
      vaultListModalOpen: false,
      postModalView: false,
      postsInReview: [],
      eventModalView: false,
      settingsModalView: false,
      selectedVault: null,
      eventForm: {
        type: null,
        date: null,
        time: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
        location: null,
        vaultId: null,
      },
    }
  },
  async created() {
    await this.fetchVaults()
    vaultAPI
      .get(`/following/${this.currentUser.id}`)
      .then(res => {
        const responseList = res.data.vault_ids
        const vaultIds = responseList.map(v => v.vault_id)
        console.log('vaultIds returned that current user is following', vaultIds)
        this.followedVaults = this.getVaults.filter(vault => vaultIds.includes(vault.id))
        this.followedVaults.forEach(async vault => {
          const lastLogin = this.currentUser.last_sign_in_at
          let vaultPosts = await postAPI.get(`/${vault.id}`)
          vault.showBell = false
          const newPosts = (await this.fetchPosts(vault.id)).filter(post => post.status === 'review')
          if (newPosts.length > 0) {
            console.log('showbell is true ', vault.firstname)
              vault.showBell = true
          }
        })
      })
      .catch(error => console.log(error))
      this.displayNewPostBells()
  },
  deactivated() {
    this.postsInReview = null
  },
  computed: {
    ...mapGetters({
      currentUser: 'getLoggedInUser',
      getVaults: 'getVaults',
    }),
    followedVaultBells() {
      let vaultBells = {}
      this.followedVaults.forEach(async vault => {
        const lastLogin = this.currentUser.last_sign_in_at
        let vaultPosts = await postAPI.get(`/${vault.id}`)
        let id = vault.id
        if (vaultPosts.data.length > 0) {
          const newestPostDate = vaultPosts.data[0].post_date
          if (new Date(lastLogin) < new Date(newestPostDate)) {
            console.log('show')
            vaultBells[id] = true
          } else {
            vaultBells[id] = false
          }
        }
      })
      return vaultBells
    },
  },
  methods: {
    ...mapActions(['fetchPosts', 'fetchVaults', 'fetchVault']),
    closeDrawer() {
      this.isDrawerOpen = false
    },
    displayNewPostBells() {
      this.managedVaults = this.getVaults.filter(v => v.user_account_id === this.currentUser.id)
        this.managedVaults.forEach(async vault => {
          let showBell = false
          const newPosts = (await this.fetchPosts(vault.id)).filter(post => post.status === 'review')
          console.log('newposts', newPosts)
          if (newPosts.length > 0) {
            console.log('showbell is true ', vault.firstname)
              showBell = true
          }
          this.managedVaultsWithBells.push({ ...vault, showBell: showBell })
        })
    },
    openDrawer() {
      this.isDrawerOpen = true
    },
    closeVaultListModal() {
      this.vaultListModalOpen = false
      this.postModalView = false
      this.eventModalView = false
      this.settingsModalView = false
    },
    async openNewPostsModal(vaultId) {
      this.postsNeedingReviewed(vaultId)
      this.vaultListModalOpen = true
      this.postModalView = true
      this.selectedVault = await this.fetchVault(vaultId)
      console.log('openNewPosts Event ', vaultId)
    },
    async openEventsModal(vaultId) {
      this.vaultListModalOpen = true
      this.eventModalView = true
      this.selectedVault = await this.fetchVault(vaultId)
      console.log('openEvents Event ', vaultId)
    },
    async openSettingsModal(vaultId) {
      this.vaultListModalOpen = true
      this.settingsModalView = true
      this.selectedVault = await this.fetchVault(vaultId)
      console.log('openSettings Event ', vaultId)
    },
    async postsNeedingReviewed(vaultId) {
      this.fetchPosts(vaultId).then(res => {
        let postsInReview = res.filter(p => p.status === 'review')
        // sort posts in reverse chronological order
        postsInReview.sort((post1, post2) => {
          if (new Date(post1.post_date).getTime() < new Date(post2.post_date).getTime()) return 1 //date 1 is newer
          if (new Date(post1.post_date).getTime() > new Date(post2.post_date).getTime()) return -1
          else return 0
        })
        this.postsInReview = postsInReview
        console.log('postsInReview', this.postsInReview)
        // only show 4 at a time until paging added
        // this.feedPosts = followedPosts.slice(0, 4)
      })
    },
    postActionHandle(event) {
      console.log('postActionHandle event ', event)
      const idx = this.postsInReview.findIndex(elem => (elem.id = event.postId))
      this.postsInReview.splice(idx, 1)
      if (this.postsInReview.length === 0) {
        this.managedVaultsWithBells = []
        this.displayNewPostBells()
        this.closeVaultListModal()
      }
    },
    submitEvent() {
      this.eventForm.vaultId = this.selectedVault.id
      eventAPI.post('/create', this.eventForm).then(res => {
        if (res.data.event) {
          this.eventModalView = false
          alert(res.data.message)
        }
      })
    },
  },
}
