import Vue from 'vue'
import Vuex from 'vuex'
import comments from './modules/comments'
import events from './modules/events'
import posts from './modules/posts'
import storage from './modules/storage'
import users from './modules/users'
import vaults from './modules/vaults'
import chatStore from './modules/chatStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    chatStore,
    comments,
    events,
    posts,
    storage,
    users,
    vaults,
  },
})
