// Copywrite Justin Roseman 2021
import { CometChat } from '@cometchat-pro/chat'
import { authAPI, userAPI } from '@/apis/apis'
import { COMETCHAT_CONSTANTS } from '../../constants/cometchat-consts'
export default {
  state: {
    accessToken: '',
    isAuthenticated: false,
    loggedInUser: null,
  },
  getters: {
    getAccessToken: state => state.accessToken,
    getLoggedInUser: state => state.loggedInUser,
  },
  mutations: {
    updateAccessToken(state, token) {
      state.accessToken = token
    },
    updateisAuthenticated(state, isAuth) {
      state.isAuthenticated = isAuth
    },
    updateLoggedInUser(state, user) {
      state.loggedInUser = user
    },
  },
  actions: {
    createUser({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        authAPI
          .post('/signup', userInfo)
          .then(res => {
            commit('')
            console.log('action new user created', res.data)
            // handle Cometchat login
            var user = new CometChat.User(res.data.user.id)
            const name = userInfo.firstname + ' ' + userInfo.lastname
            user.setName(name)
            CometChat.createUser(user, COMETCHAT_CONSTANTS.AUTH_KEY).then(
              user => {
                console.log('user created', user)
              },
              error => {
                console.log('error', error)
              }
            )
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
    getUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        userAPI
          .get(`/${id}`)
          .then(res => {
            resolve(res.data.user)
          })
          .catch(error => reject(error))
      })
    },
    login({ commit }, userLoginInfo) {
      return new Promise((resolve, reject) => {
        authAPI
          .post('/signin', userLoginInfo)
          .then(async res => {
            if (res.status === 200) {
              console.log('res', res)
              commit('updateLoggedInUser', res.data.user)
              commit('updateAccessToken', res.data.session.access_token)
              commit('updateisAuthenticated', true)
              sessionStorage.setItem('user', JSON.stringify(res.data.user))
              sessionStorage.setItem('access_token', res.data.session.access_token)
              sessionStorage.setItem('refresh_token', res.data.session.refresh_token)

              // Retrive Cometchat auth token
              const chatTokenResponse = await fetch(`https://api-us.cometchat.io/v3.0/users/${res.data.user.id}/auth_tokens`, {
                method: 'post',
                headers: {
                  'content-type': 'application/json',
                  accept: 'application/json',
                  apikey: 'db2712e7bb8350e18b603402077869db4cde899c',
                  appid: '206735c37a8a04e8',
                },
              })
              const result = await chatTokenResponse.json()
              console.log('cometchat result', result)

              // Handle Cometchat login
              CometChatWidget.init({
                appID: '206735c37a8a04e8',
                appRegion: 'us',
                authKey: 'c75d54ffcaad0c8445a986d3cc18e62cf3bae430',
              }).then(() => {
                console.log('Initialization completed successfully')
                CometChat.login(result.data.authToken).then(
                  user => {
                    CometChatWidget.launch({
                      widgetID: '8a2fc823-cbda-4d69-a577-69161ce2291c',
                      docked: 'true',
                      target: '#cometchat',
                      alignment: 'right', //left or right
                      roundedCorners: 'true',
                      height: '450px',
                      width: '400px',
                      defaultID: res.data.user.id, //default UID (user) or GUID (group) to show,
                      defaultType: 'user', //user or group
                    }).then(response => {
                      CometChatWidget.on('openChat', args => {
                        console.log('CometChatWidget Chat is open', args)
                      })
                      CometChatWidget.on('closeChat', args => {
                        console.log('CometChatWidget Chat is closed', args)
                      })
                      CometChatWidget.on('onMessageReceived', message => {
                        console.log('CometChatWidget onMessageReceived', message)
                      })
                    })
                    console.log('Cometchat Login Successful:', { user })
                  },
                  error => {
                    console.log('Initialization failed with error:', error)
                    //Check the reason for error and take appropriate action.
                  }
                )
              })

              resolve(res.data)
            } else {
              throw new Error('Invalid Login')
            }
          })
          .catch(error => reject(error))
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        authAPI
          .post('/signout')
          .then(res => {
            if (res.signedOut) {
              commit('updateLoggedInUser', null)
              commit('updateAccessToken', null)
              resolve(res.data)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}
