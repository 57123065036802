const validateEmail = email => {
  const regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
  const isValidEmail = typeof email == 'string' && email.trim() != '' && regex.test(email)
  return isValidEmail
}

const validatePassword = password => {
  if (password === '') {
    console.log('Please input the password')
  } else if (password.length < 6) {
    console.log('Password length must be 6 characters or more')
  } else {
    return true
  }
  return false
}

const confirmPasswordsMatch = (password, confirmPassword) => password.trim().toLowerCase() === confirmPassword.trim().toLowerCase()

export { confirmPasswordsMatch, validateEmail, validatePassword }
