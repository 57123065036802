// Copywrite Justin Roseman 2021
import { store } from '../store/index'
import axios from 'axios'
import { USER_URL, VAULT_URL, COMMENT_URL, EVENT_URL, EVENT_LIST_URL, LIKE_URL, POST_URL, AUTH_URL, STORAGE_URL } from './apiUrls'

export const authAPI = axios.create({
  baseURL: AUTH_URL,
  // withCredentials: false,
  headers: {
    'content-type': 'application/json',
    // 'api-version': 1,
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Origin': 'http://lifeshare-frontend.s3-website-us-east-1.amazonaws.com/, localhost:8080, https://lifshare.com',
    // 'Access-Control-Allow-Methods': 'GET,POST',
    // 'Access-Control-Allow-Headers': '*',
  },
  // crossDomain: true,
})

export const userAPI = axios.create({
  baseURL: USER_URL,
  // withCredentials: false,
  headers: {
    'content-type': 'application/json',
    // 'api-version': 1,
  },
  // crossDomain: true,
})

export const commentAPI = axios.create({
  baseURL: COMMENT_URL,
  // withCredentials: false,
  headers: {
    'content-type': 'application/json',
    // 'api-version': 1,
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Headers': '*',
  },
  // crossDomain: true,
})

export const eventAPI = axios.create({
  baseURL: EVENT_URL,
  // withCredentials: false,
  headers: {
    'content-type': 'application/json',
    // 'api-version': 1,
  },
  // crossDomain: true,
})

export const eventListAPI = axios.create({
  baseURL: EVENT_LIST_URL,
  headers: {
    'content-type': 'application/json',
  },
})

export const likeAPI = axios.create({
  baseURL: LIKE_URL,
  // withCredentials: false,
  headers: {
    'content-type': 'application/json',
    // 'api-version': 1,
  },
  // crossDomain: true,
})

export const postAPI = axios.create({
  baseURL: POST_URL,
  // withCredentials: false,
  headers: {
    'content-type': 'application/json',
    // 'api-version': 1,
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Headers': '*',
    // Authorization: `Bearer ${}`,
  },
  // crossDomain: true,
})

export const vaultAPI = axios.create({
  baseURL: VAULT_URL,
  // withCredentials: false,
  headers: {
    'content-type': 'application/json',
    // 'api-version': 1,
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Headers': '*',
  },
  // crossDomain: true,
})

export const storageAPI = axios.create({
  baseURL: STORAGE_URL,
  // withCredentials: false,
  headers: {
    'content-type': 'multipart/form-data',
    // 'api-version': 1,
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Origin': 'http://lifeshare-frontend.s3-website-us-east-1.amazonaws.com/',
    // 'Access-Control-Allow-Methods': 'GET,POST',
    // 'Access-Control-Allow-Headers': '*',
    // ExposeHeaders: ['Access-Control-Allow-Origin'],
    // 'authorization': 'Bearer ' + getAccessToken()
  },
  // crossDomain: true,
})
