// Copywrite Justin Roseman 2021

import { mapActions, mapGetters } from 'vuex'
import { vaultAPI } from '@/apis/apis'

export default {
  name: 'post-feed',
  props: [],
  data() {
    return {
      count: 5,
      feedPosts: [],
      followedPosts: [],
      followedVaults: [],
      loading: false,
    }
  },
  async created() {
    this.loading = true
    let posts = await this.fetchAllPosts()

    await this.fetchVaults()
    vaultAPI
      .get(`/following/${this.currentUser.id}`)
      .then(res => {
        // filter out posts so they're only from vault accounts that this user is following
        const responseList = res.data.vault_ids
        const vaultIds = responseList.map(v => v.vault_id)
        this.followedPosts = posts.filter(p => vaultIds.includes(p.vault_account_id) && p.status === 'approved')

        // filter only the posts that were added since users last sign in
        // auth/users last_sign_in_at
        // public/post post_date

        // sort posts in reverse chronological order
        this.followedPosts.sort((post1, post2) => {
          if (new Date(post1.post_date).getTime() < new Date(post2.post_date).getTime()) return 1 //date 1 is newer
          if (new Date(post1.post_date).getTime() > new Date(post2.post_date).getTime()) return -1
          else return 0
        })

        // only show 4 at a time until paging added
        this.feedPosts = this.followedPosts.slice(0, 5)
      })
      .catch(error => console.log(error))

    this.loading = false
  },
  methods: {
    ...mapActions(['fetchAllPosts', 'fetchPosts', 'fetchVaults']),
    load() {
      if (this.followedPosts.length >= this.count + 6) {
        let ps = this.followedPosts.slice(this.count + 1, this.count + 6)
        this.feedPosts.push(...ps)
        this.count += 5
      }
    },
    goToPost(post) {
      this.$router.push({ name: `vault`, params: { id: post.vault_account_id }, query: { postId: post.id } })
    },
    tryThis(post) {
      return this.getUser(post.user_account_id).then(data => {
        let user = JSON.stringify(data)
        return { ...post, fullname: user.firstname + ' ' + user.lastname, avatar: user.avatar }
      })
    },
    async postedBy(userId) {
      let user
      await this.getUser(userId).then(user => {
        console.log('users', user)
        user = user.firstname + user.lastname
      })
      return user
    },
    postedByProfileImg(userId) {
      let fullname
      this.getUser(userId).then(user => {
        console.log('users', user)
        fullname = user
      })
      return fullname
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getLoggedInUser',
      getVaults: 'getVaults',
    }),
  },
}
