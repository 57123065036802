// Copywrite Justin Roseman 2021

import { mapActions, mapGetters } from 'vuex'
import Event from './event'

export default {
  name: 'event-feed',
  props: [],
  data() {
    return {
      feedEvents: [],
      vaults: [],
    }
  },
  components: {
    Event,
  },
  async created() {
    this.vaults = this.getVaults
    let res = await this.fetchFollowedVaultIds(this.user.id)
    let followedVaultIds = res.map(v => v.vault_id)
    let events = await this.fetchAllEvents()
    this.feedEvents = events.filter(event => followedVaultIds.includes(event.vault_id))
    // sort posts in chronological order
    this.feedEvents.sort((event1, event2) => {
      if (new Date(event1.date).getTime() > new Date(event2.date).getTime()) return 1 //date 1 is newer
      if (new Date(event1.date).getTime() < new Date(event2.date).getTime()) return -1
      else return 0
    })
    // format event dates
    this.feedEvents.forEach(event => {
      event.date = new Date(event.date).toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })
    })
  },
  methods: {
    ...mapActions(['fetchAllEvents', 'fetchFollowedVaultIds']),
    passSelectedEventToParent(event) {
      console.log('hey ', event)
      this.$emit('event-selected', event)
    },
    getAvatar(vaultId) {
      let avatar = 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?f=y'
      const vault = this.vaults.find(vault => vault.id === vaultId)
      if (vault && vault.avatar !== null) {
        avatar = vault.avatar
      }
      return avatar
    },
    // TODO come back to finish
    load() {
      if (this.events && this.events.length >= this.count + 4) {
        let ps = this.events.slice(this.count + 1, this.count + 4)
        this.feedPosts.push(...ps)
        this.count += 3
      }
    },
    postedBy(userId) {
      return userId
    },
  },
  computed: {
    ...mapGetters({
      user: 'getLoggedInUser',
      getVaults: 'getVaults',
    }),
  },
}
