// Copywrite Justin Roseman 2021
import { vaultAPI } from '@/apis/apis'

export default {
  state: {
    vaults: [],
    vault: {},
    followedVaultIds: [],
  },
  getters: {
    getFollowedVaults: state => state.followedVaultIds,
    getVaults: state => state.vaults,
  },
  mutations: {
    updateVaults(state, vaults) {
      state.vaults = vaults
    },
    updateVault(state, vault) {
      state.vault = vault
    },
    updateFollowedVaults(state, vaults) {
      state.followedVaultIds = vaults
    },
  },
  actions: {
    fetchVaults({ commit }) {
      return new Promise((resolve, reject) => {
        vaultAPI
          .get('/all')
          .then(res => {
            console.log('res fetchVaults', res)
            commit('updateVaults', res.data)
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchVault({ commit }, id) {
      return new Promise((resolve, reject) => {
        vaultAPI
          .get(`/${id}`)
          .then(res => {
            commit('updateVault', res.data)
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
    followVault({ commit }, payload) {
      return new Promise((resolve, reject) => {
        vaultAPI
          .post(`/follow/${payload.vaultId}`, { userId: payload.userId })
          .then(res => {
            commit('')
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
    unFollowVault({ commit }, payload) {
      console.log('store ', payload.userId)
      return new Promise((resolve, reject) => {
        vaultAPI
          .delete(`/unfollow/${payload.vaultId}`, { userId: payload.userId })
          .then(res => {
            commit('')
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchFollowedVaultIds({ commit }, userId) {
      return new Promise((resolve, reject) => {
        vaultAPI
          .get(`/following/${userId}`)
          .then(res => {
            commit('updateFollowedVaults', res.data.vault_ids)
            resolve(res.data.vault_ids)
          })
          .catch(error => reject(error))
      })
    },
  },
}
