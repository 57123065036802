// Copywrite Justin Roseman 2021

import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { vaultAPI } from '@/apis/apis'
import geo from '@/constants/geo-info'
import Alert from '@/components/alert'

export default {
  name: 'create-vault',
  props: [],
  data() {
    return {
      accountIsUnique: false,
      alert: false,
      alertTitle: 'Is this the person you are trying to create an account for?',
      exisitingAccount: {},
      feedPosts: [],
      imageUrl: '',
      profile: {
        firstname: '',
        middlename: '',
        lastname: '',
        dob: null,
        dod: null,
        country: null,
        state: null,
        city: null,
        bio: '',
      },
      cityList: [],
      countryList: [],
      stateList: [],
    }
  },
  components: {
    Alert
  },
  async created() {
    const posts = await this.fetchPosts('76ded346-3b76-11ec-8d3d-0242ac130003')
    this.feedPosts = posts.slice(0, 4)
    this.countryList = geo.countryList
    this.stateList = geo.stateList
    this.cityList = geo.cityList
    console.log('created uuid', uuidv4())
  },
  methods: {
    ...mapActions(['fetchPosts']),
    allowVaultCreation() {
      this.alert = false
      this.accountIsUnique = true
    },
    createVaultAccount() {
      const payload = {
        id: uuidv4(),
        firstname: this.profile.firstname,
        middlename: this.profile.middlename,
        lastname: this.profile.lastname,
        birthday: this.profile.dob,
        deathday: this.profile.dod,
        country: this.profile.country,
        state_born_in: this.profile.state,
        city_born_in: this.profile.city,
        bio: this.profile.bio,
        privacy: false,
        user_account_id: this.user.id,
      }
      vaultAPI
        .post('/create', payload)
        .then(res => {
          console.log('This is Response from Create New Vault ', res.data.vault[0].id)
          this.$router.push({ name: 'vault', params: { id: res.data.vault[0].id } })
        })
        .catch(error => console.log(error))
    },
    checkUnique() {
      const existingAccounts = this.vaults.filter(v => {
        const bod = new Date(v.birthday.replace(/-/g, '/').replace(/T.+/, ''))
        const dod = new Date(v.deathday.replace(/-/g, '/').replace(/T.+/, ''))
        const dobMonth = bod.getMonth() < 10 ? '0' + (bod.getMonth() + 1) : bod.getMonth() + 1
        const dodMonth = dod.getMonth() < 10 ? '0' + (dod.getMonth() + 1) : dod.getMonth() + 1

        return (
          v.firstname.toLowerCase() === this.profile.firstname.toLowerCase() && 
          v.lastname.toLowerCase() === this.profile.lastname.toLowerCase() &&
          `${bod.getFullYear()}-${dobMonth}-${bod.getDate()}` === this.profile.dob &&
          `${dod.getFullYear()}-${dodMonth}-${dod.getDate()}` === this.profile.dod
        )
      })

      console.log('exisiting accounts found: ', existingAccounts.length)
      if (existingAccounts.length === 0) {
        this.accountIsUnique = true
        this.alert = false
      } else {
        this.accountIsUnique = false
        this.exisitingAccount = existingAccounts[0]
        console.log('existing', existingAccounts[0])
        this.alert = true
      }
    },
    goToVaultAccount() {
      this.$router.push({
        name: 'vault',
        params: { id: this.exisitingAccount.id },
      })
    },
  },
  computed: {
    ...mapGetters({
      vaults: 'getVaults',
      user: 'getLoggedInUser',
    }),
  },
}
