// Copywrite Justin Roseman 2021
import ArticleFeed from '../../../components/article-feed/index.vue'
import EventFeed from '../../../components/event-feed/index.vue'
import PostFeed from '../../../components/post-feed/index.vue'
import { eventListAPI } from '../../../apis/apis'

export default {
  name: 'control-home',
  data() {
    return {
      rsvpList: [],
      showEventDetails: false,
      shownEvent: {},
    }
  },
  components: {
    ArticleFeed,
    EventFeed,
    PostFeed,
  },
  computed: {
    startTime() {
      const d = new Date(this.shownEvent.start_time)
      const dd = [d.getHours(), d.getMinutes()].map(a => (a < 10 ? a : a))
      let postFix
      if (d.getHours() >= 12) postFix = 'PM'
      else postFix = 'AM'
      return dd.join(':') + postFix
    },
    endTime() {
      const d = new Date(this.shownEvent.end_time)
      const dd = [d.getHours(), d.getMinutes()].map(a => (a < 10 ? a : a))
      let postFix
      if (d.getHours() >= 12) postFix = 'PM'
      else postFix = 'AM'
      return dd.join(':') + postFix
    },
    showBuyFlowersBtn() {
      return (
        this.shownEvent &&
        this.shownEvent.type &&
        (this.shownEvent.type.toLowerCase() === 'funeral' ||
          this.shownEvent.type.toLowerCase() === 'visitation' ||
          this.shownEvent.type.toLowerCase() === 'celebration of life')
      )
    },
  },
  methods: {
    closeModal() {
      this.showEventDetails = false
      this.rsvpList = []
    },
    displayEvent(event) {
      this.shownEvent = event
      this.showEventDetails = true
      eventListAPI.get(`/${this.shownEvent.id}`).then(res => {
        res.data.forEach(record => {
          console.log('record 1', record)
          this.rsvpList.push(record.userData)
        })
      })
    },
  },
}
