// Copywrite Justin Roseman 2021
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import axios from 'axios'
import BASE_URL from '@/apis/apiUrls'

export default {
  name: 'control-search',
  data() {
    return {
      page: 1,
      searchTerm: '',
      totalVaultCount: 0,
      vaults: [],
      vaultsDisplayed: [],
    }
  },
  components: {
    InfiniteLoading,
  },
  async created() {
    this.vaults = await this.fetchVaults()
    const response = axios.get(`${BASE_URL}/lifeshare/v1/models/vault/?page=1`)
    this.totalVaultCount = response.total
    this.vaultsDisplayed = this.vaults
  },
  computed: {
    url() {
      return `${BASE_URL}/lifeshare/v1/models/vault/?page=${this.page}`
    },
  },
  methods: {
    ...mapActions(['fetchVaults', 'fetchVaultCount']),
    getAvatar(vaultId) {
      let avatar = 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?f=y'
      const vault = this.vaults.find(vault => vault.id === vaultId)
      if (vault.avatar !== null) {
        avatar = vault.avatar
      }
      return avatar
    },
    infiniteScroll($state) {
      if (this.page + 1 * 3 < this.totalVaultCount) {
        setTimeout(() => {
          this.page++
          axios
            .get(this.url)
            .then(response => {
              console.log('response for is', response.data)
              if (response.data.vaults.length > 1) {
                response.data.vaults.forEach(item => this.vaultsDisplayed.push(item))
                if (this.page === 2) this.page++
                $state.loaded()
              } else {
                $state.complete()
              }
            })
            .catch(err => {
              console.log(err)
            })
        }, 700)
      } else {
        $state.complete()
      }
    },
    searchVaults(e) {
      console.log('event', e)
      if (this.searchTerm === '') {
        this.vaultsDisplayed = this.vaults
      } else {
        this.vaultsDisplayed = this.vaults.filter(
          v => v.firstname.toLowerCase().includes(this.searchTerm.toLowerCase()) || v.lastname.toLowerCase().includes(this.searchTerm.toLowerCase())
        )
      }
    },
  },
  watch: {
    searchTerm(val) {
      if (val === '') {
        this.vaultsDisplayed = this.vaults
      } else {
        this.vaultsDisplayed = this.vaults.filter(
          v => v.firstname.toLowerCase().includes(this.searchTerm.toLowerCase()) || v.lastname.toLowerCase().includes(this.searchTerm.toLowerCase())
        )
      }
    },
  },
}
