// Copywrite Justin Roseman 2021
import { eventAPI } from '@/apis/apis'

export default {
  state: {
    events: [],
  },
  getters: {},
  mutations: {},
  actions: {
    fetchAllEvents() {
      return new Promise((resolve, reject) => {
        eventAPI
          .get('/')
          .then(res => {
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
