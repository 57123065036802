// Copywrite Justin Roseman 2021

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'create-vault-checker',
  props: [],
  data() {
    return {
      accountIsUnique: true,
      createVault: {
        fullname: '',
        dob: null,
        dod: null,
      },
      disabled: true,
      exisitingAccountDetails: {},
      feedPosts: [],
      profile: {
        firstname: '',
        lastname: '',
        dob: null,
        dod: null,
      },
    }
  },
  async created() {
    const posts = await this.fetchPosts('76ded346-3b76-11ec-8d3d-0242ac130003')
    this.feedPosts = posts.slice(0, 4)
  },
  methods: {
    ...mapActions(['fetchPosts']),
    checkUnique() {
      const existingAccounts = this.vaults.filter(v => {
        const name = this.createVault.fullname.split(' ')
        const bod = new Date(v.birthday.replace(/-/g, '/').replace(/T.+/, ''))
        const dod = new Date(v.deathday.replace(/-/g, '/').replace(/T.+/, ''))
        console.log(`${bod.getMonth() + 1}/${bod.getDate()}/${bod.getFullYear()}`)
        return (
          v.firstname.toLowerCase() + v.lastname.toLowerCase() === name.join('').toLowerCase() &&
          `${bod.getMonth() + 1}/${bod.getDate()}/${bod.getFullYear()}` === this.createVault.dob &&
          `${dod.getMonth() + 1}/${dod.getDate()}/${dod.getFullYear()}` === this.createVault.dod
        )
      })

      if (existingAccounts.length === 0) {
        this.accountIsUnique = true
        this.disabled = false
      } else {
        this.accountIsUnique = false
        this.disabled = true
        this.exisitingAccountDetails = existingAccounts[0]
        console.log('existing', existingAccounts[0])
        this.$parent.$emit('alert', {
          title: 'Is this the person you are trying to create an account for?',
          accountIsUnique: this.accountIsUnique,
          existingAccountDetails: existingAccounts[0],
        })
      }
    },
    allowCreation() {
      this.disabled = false
      this.accountIsUnique = true
    },
    goToVaultAccount() {
      this.$router.push({
        name: 'vault',
        params: { id: this.exisitingAccountDetails.id },
      })
    },
    createVaultAccount() {
      // validate all input
      // show alert if empty or wrong format
      // else open modal and continue gathering data for account
      this.$emit('createVaultClicked')
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3)
    },
  },
  computed: {
    ...mapGetters({
      vaults: 'getVaults',
    }),
  },
}
