<template>
  <div class="reset-password">
    <img src="@/assets/Lifeshare_Logo.svg" class="logo" />
    <form class="reset" @submit.prevent="reset">
      <div class="passwords">
        <input v-model="resetForm.password" type="password" placeholder="new password" />
        <input v-model="resetForm.confirm_password" type="password" placeholder="confirm new password" />
      </div>
      <button class="reset_btn" type="submit">Reset</button>
    </form>
  </div>
</template>

<script>
import { confirmPasswordsMatch, validatePassword } from '../utils/validate'
import { authAPI } from '../apis/apis'

export default {
  name: 'reset-password',
  data() {
    return {
      resetForm: {
        password: '',
        confirm_password: '',
      },
    }
  },
  methods: {
    reset() {
      console.log('reset page token param', this.$route.params.token)
      const validPass = validatePassword(this.resetForm.password)
      const match = confirmPasswordsMatch(this.resetForm.password, this.resetForm.confirm_password)
      if (validPass && match) {
        authAPI
          .post(
            '/reset-password',
            {
              password: this.resetForm.password,
              access_token: this.$route.params.token,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$route.params.token}`,
              },
            }
          )
          .then(() => this.$router.push({ name: 'landingPage' }))
      }
    },
  },
}
</script>

<style lang="scss">
.reset-password {
  min-height: 100vh;
  content: '';
  background: linear-gradient(0deg, rgba(0, 110, 255, 0.3), rgba(0, 47, 255, 0.3)), url('../assets/vault_bg_color_light.png');
  background-image: url('../assets/vault_bg_color_light.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 200px;
    display: block;
    margin: 0 auto;
    padding-top: 25vh;
    position: relative;
    right: 0.25rem;
  }

  .reset {
    margin-top: 2rem;
    input {
      padding: 1rem;
      border-radius: 1rem;
      width: 340px;
      margin: 0.5rem 0;
      border: none;
      &:focus {
        outline: none;
      }
    }
    .passwords {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      cursor: pointer;
      span {
        color: white;
        margin-top: 0.5rem;
        position: relative;
        right: 0.5rem;
      }
    }
    &_btn {
      display: block;
      margin: 0 auto;
      background-color: #4ab59f;
      color: white;
      font-family: sans-serif;
      font-size: 1.125rem;
      font-weight: 500;
      padding: 1rem 1.5rem;
      border-radius: 1.5rem;
      border: none;
      margin-top: 1.5rem;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &:hover {
        background-color: darken(#4ab59f, 7%);
      }
    }
  }
}
</style>
