import Vue from 'vue'
import VueTooltip from 'v-tooltip'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import './scss/element-variables.scss';
import locale from 'element-ui/lib/locale/lang/en'

import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import { CometChat } from '@cometchat-pro/chat'
import { COMETCHAT_CONSTANTS } from './constants/cometchat-consts'

import VueSocialSharing from 'vue-social-sharing'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import App from './app.vue'
import router from './router'
import store from './store'

Vue.use(VueTooltip)
Vue.use(VueSocialSharing)
Vue.use(ElementUI, { locale })
library.add(fas)
library.add(far)
library.add(fab)

Vue.component('vue-advanced-cropper', Cropper)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(COMETCHAT_CONSTANTS.REGION).build()

CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})
