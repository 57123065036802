// Copywrite Justin Roseman 2021
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'control-settings',
  props: [],
  data() {
    return {
      avatarImg: '',
      file: null,
      form: {
        posts: true,
        messages: true,
        complaint: false,
      },
      followForm: {
        posts: false,
        messages: false,
        events: true,
      },
    }
  },
  methods: {
    ...mapActions(['uploadProfileImage']),
    onImageSelect() {
      console.log('image selected')
      this.file = this.$refs.fileInput.files[0]
      console.log('this is file info ', this.file)
      this.avatarImg = URL.createObjectURL(this.file)
    },
    async onImageSubmit() {
      console.log('entered onImageSubmit', this.file)
      if (!this.file) {
        alert('must select image before uploading')
        return
      }
      const formData = new FormData()
      formData.append('file', this.file)
      console.log('user id', this.user.id)
      const response = await this.uploadProfileImage({ id: this.user.id, formData })
      console.log('RESPONSE FROM BE ', response.file)
      this.avatarImg = `${BASE_URL}/${response.file.path}`
    },
  },
  computed: {
    ...mapGetters({
      user: 'getLoggedInUser',
      accessToken: 'getAccessToken',
    }),
  },
}
