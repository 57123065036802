// Copywrite Justin Roseman 2021
import Vue from 'vue'
import VueRouter from 'vue-router'
import { BASE_URL } from '../apis/apiUrls'
import ControlPanel from '../views/control-panel/index.vue'
import ControlHome from '../views/control-panel/control-home/index.vue'
import ControlVaults from '../views/control-panel/control-vaults/index.vue'
import ControlMessages from '../views/control-panel/control-messages/index.vue'
import ControlSearch from '../views/control-panel/control-search/index.vue'
import ControlSettings from '../views/control-panel/control-settings/index.vue'
import LandingPage from '../views/landing-page/index.vue'
import ResetPassword from '../views/reset-password.vue'
import Terms from '../views/static-pages/terms-conditions.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landingPage',
    component: LandingPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset/:token',
    name: 'reset',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/vault/:id?',
    name: 'vault',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "vault" */ '../views/vault/index.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: 'messages',
    name: 'messages',
    component: ControlMessages,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/control-panel/:id',
    name: 'controlPanel',
    component: ControlPanel,
    meta: { requiresAuth: true, breadcrumb: [{ name: 'controlPanel', to: '/controlPanel' }] },
    children: [
      {
        path: 'home',
        name: 'home',
        component: ControlHome,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'vaults',
        name: 'vaults',
        component: ControlVaults,
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: 'messages',
      //   name: 'messages',
      //   component: ControlMessages,
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: 'settings',
        name: 'settings',
        component: ControlSettings,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'search',
        name: 'search',
        component: ControlSearch,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store._modules.root.state.users.isAuthenticated) {
      console.log('not authenticated', to)
      next({
        path: `/?redirectTo=${to.name}&&params=${to.params.id}&&postId=${to.query.postId}`,
        params: { nextUrl: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
