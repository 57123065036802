// Copywrite Justin Roseman 2021

import { mapActions } from 'vuex'
import { postAPI } from '@/apis/apis'

export default {
  name: 'post-review',
  props: ['postsInReview'],
  data() {
    return {
      loading: false,
    }
  },
  async created() {},
  methods: {
    ...mapActions([]),
    async postedBy(userId) {
      let user
      await this.getUser(userId).then(user => {
        console.log('users', user)
        user = user.firstname + user.lastname
      })
      return user
    },
    postedByProfileImg(userId) {
      let fullname
      this.getUser(userId).then(user => {
        console.log('users', user)
        fullname = user
      })
      return fullname
    },
    postAction(post, status) {
      if (status === 'approved') {
        let postData = { description: post.description, status: status }
        postAPI.put(`/${post.id}`, postData).then(res => {
          console.log(res.data.message)
          this.$emit('postActionComplete', { postId: post.id })
        })
      } else if (status === 'denied') {
        postAPI.delete(`/${post.id}`).then(res => {
          if (res.status === 204) 
            this.$emit('postActionComplete', { postId: post.id })
        })
      }
    },
  },
  computed: {},
}
