// Copywrite Justin Roseman 2021
import { mapActions } from 'vuex'
import { confirmPasswordsMatch, validateEmail, validatePassword } from '../../utils/validate'
import { authAPI } from '../../apis/apis'

export default {
  name: 'landing-page',
  data() {
    return {
      createAccountModal: false,
      createAccountForm: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      credentials: {
        email: '',
        password: '',
      },
      loginErrorMsg: null,
      redirectErrorMsg: null,
      verifyEmail: false,
      resetEmail: false,
    }
  },
  async created() {
    if (this.$route.query.redirectTo) {
      this.redirectErrorMsg = 'You must be logged in to access this page'
    }
    const hash = window.location.hash
    if (hash && hash.substr(0, 1) === '#') {
      const tokens = hash.substr(1).split('&')
      const entryPayload = {}
      tokens.map(token => {
        const pair = (token + '=').split('=')
        entryPayload[pair[0]] = pair[1]
      })
      if (entryPayload?.type === 'recovery') {
        // const access_token = this.$route.query.access_token
        console.log('here is password reset access token ', entryPayload)
        setTimeout(() => {
          // password recovery link
          this.$router.push({ name: 'reset', params: { token: entryPayload.access_token } })
        }, 1000)
      }
    }
  },
  methods: {
    ...mapActions(['createUser', 'login', 'logout']),
    createAccount() {
      if (confirmPasswordsMatch(this.createAccountForm.password, this.createAccountForm.confirmPassword)) {
        const namesExist = this.createAccountForm.firstname.trim().length > 0 && this.createAccountForm.lastname.trim().length > 0
        if (this.createAccountForm.email && namesExist) {
          console.log('valid email verified!')
          this.createUser(this.createAccountForm).then(res => {
            console.log('created account response', res)
            this.verifyEmail = true
            setTimeout(() => {
              this.verifyEmail = false
            }, 5000)
          })
          this.verifyEmail = true
          setTimeout(() => {
            this.verifyEmail = false
          }, 5000)
        }
      }
      this.createAccountModal = false
    },
    logUserin() {
      const validFormData = validateEmail(this.credentials.email) && validatePassword(this.credentials.password)
      if (validFormData) {
        this.login({ email: this.credentials.email, password: this.credentials.password })
          .then(res => {
            console.log('logged in', res)
            this.loginErrorMsg = res.message !== 'Auth successful' ? res.message : ''
            // check if user tried to access a page without being authenticated then after
            // logging in send them to the page they were trying to access
            if (this.$route.query.redirectTo) {
              this.$router.push({
                name: this.$route.query.redirectTo,
                params: { id: this.$route.query.params },
                query: { postId: this.$route.query.postId },
              })
            } else {
              this.$router.push({ name: 'home', params: { id: res.user.id } }).catch(error => console.log('error to home', error))
            }
          })
          .catch(err => {
            console.log('login error 400. See FE', err)
          })
      } else {
        console.log('error submit!!')
      }
    },
    googleSSO() {
      authAPI.post('/signin-goggle').then(res => {
        console.log('google signin response ', res)
        // this.$router.push({ name: 'home', params: { id: res.user.id } }).catch(error => console.log('error to home', error))
      })
    },
    sendForgotPasswordEmail() {
      authAPI.post('/forgot-password', { email: this.credentials.email }).then(res => {
        console.log('email sent ', res.data)
        this.resetEmail = true
        setTimeout(() => {
          this.resetEmail = false
        }, 5000)
      })
    },
  },
}
