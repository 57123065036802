// Copywrite Justin Roseman 2021
import { loadScript } from 'vue-plugin-load-script'

import { mapActions, mapGetters } from 'vuex'
import ControlHome from '@/views/control-panel/control-home/index.vue'
import ControlMessages from '@/views/control-panel/control-messages/index.vue'
import ControlSearch from '@/views/control-panel/control-search/index.vue'
import ControlSettings from '@/views/control-panel/control-settings/index.vue'
import ControlVaults from '@/views/control-panel/control-vaults/index.vue'
import { authAPI, userAPI } from '../../apis/apis'

export default {
  name: 'controlPanel',
  data() {
    return {
      activeTab: 'home',
      avatarImg: '',
      file: null,
      image: null,
    }
  },
  components: {
    ControlHome,
    ControlMessages,
    ControlSearch,
    ControlSettings,
    ControlVaults,
  },
  mounted() {
    let chatId = document.createElement('div')
    chatId.setAttribute('id', 'cometchat')
    document.body.appendChild(chatId)
  },
  created() {
    userAPI.get(`avatar/${this.user.id}`).then(res => {
      this.avatarImg = res.data && res.data.avatar ? res.data.avatar : null
    })
  },
  methods: {
    ...mapActions(['uploadProfileImage']),
    closeChatWidget() {
      CometChatWidget.openOrCloseChat(false)
    },
    async onImageSelect() {
      console.log('image selected')
      this.file = this.$refs.fileInput.files[0]
      this.avatarImg = URL.createObjectURL(this.file)
      this.onImageSubmit()
    },
    async onImageSubmit() {
      console.log('entered onImageSubmit')
      const formData = new FormData()
      formData.append('profile-upload', this.file)
      const response = await this.uploadProfileImage({ id: this.user.id, type: 'user', formData })
      console.log('UPLOAD PROFILE PIC - RESPONSE FROM BE ', response.file.bucket + response.file.key)
      if (response.file) {
        this.avatarImg = `${response.file.location}`
      }
    },
    signout() {
      authAPI.post('/signout').then(res => {
        console.log('signed out')
        this.$router.push({ name: 'landingPage' })
      })
      let chatId = document.getElementById('cometchat')
      chatId.remove()
      let chatWidgetId = document.getElementById('cometchat__widget')
      chatWidgetId.remove()
    },
  },
  computed: {
    ...mapGetters({
      user: 'getLoggedInUser',
      accessToken: 'getAccessToken',
    }),
    showSearchbar() {
      return this.$route.name === 'search'
    },
  },
}
