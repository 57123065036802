import { mapGetters } from 'vuex'
import { eventListAPI } from '../../../apis/apis'

export default {
  name: 'event',
  props: ['event'],
  data() {
    return {
      numGoing: 0,
      rsvpd: false,
    }
  },
  created() {
    eventListAPI.get(`/${this.event.id}`).then(res => {
      this.numGoing = res.data.length
      this.rsvpd = false
      res.data.forEach(record => {
        if (record.user_id === this.user.id) this.rsvpd = true
      })
    })
  },
  methods: {
    addOrRemoveFromEventList() {
      const payload = {
        id: this.event.id,
        user_id: this.user.id,
      }
      if (!this.rsvpd) {
        eventListAPI.post('/create', payload).then(res => {
          console.log('rsvp: ', res.rsvp + ' message: ', res.message)
          this.numGoing += 1
          this.rsvpd = true
        })
      } else {
        eventListAPI.delete(`/${this.user.id}`).then(res => {
          console.log('rsvp deleted? ', res.message)
          this.numGoing -= 1
          this.rsvpd = false
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getLoggedInUser',
    }),
  },
}
