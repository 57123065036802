
<template>
  <div class="terms">
      <h1>Terms & Conditions</h1>
        <p>At Lifeshare (the “Company”), we know that online social platforms, including blogs, wikis, message boards, video and 
        photo sharing websites, 
        and social networking services, are constantly transforming the way we interact. We also recognize the importance of the Internet in shaping 
        the public view of our company.</p><p>The Company is committed to supporting your right to interact responsibly and knowledgeable 
            on the Internet 
        through blogging and interaction in social media. We want our members to share and learn from others in order to build a valuable 
        online community.</p>
        <p>The purpose of these guidelines is two-fold: First, the Company has an aim to protect our interests. Including, but not limited to, 
            the privacy 
        of our employees and confidentiality regarding our business purpose, plans, partners, users, and competitors. Second, these 
        guidelines will help
         you make respectful and appropriate decisions about your work-related interactions with people on the Internet.</p>
        
        <h3>Respect and Privacy Rights</h3>
        <ul>
            <li>Use common sense</li>
            <li>Speak respectfully to other users.</li>
            <li>Refrain from using fowl language </li>
            <li>Be considerate of those grieving</li>
        </ul>
        <h3>Ownership of Content</h3>
        <p>Lifeshare has to right to print, reproduce, sell products using the media content uploaded to the site. The products are only sold to 
        users of 
        the site that want to have a physical item to remember the deceased by. Lifeshare will not share this content with any third party, unless 
        it is in reference to creating the products offered on the site. </p>
        <h3>Non-Competition</h3>
        <p>You may not create, work on or sell any product or service that would compete with any of the Company’s products or services without 
        permission in writing from the CEO. This includes, but is not limited to photos, shirts, calendars, trainings, books, products,
        and freelance writing.</p>
        <h3>Transparency and Disclosures</h3>

        <p>By Entering this site and/or signing up for an account</p>
        <p>You agree not to design, contribute to, build, or code a website, web app or mobile app that allows users to
         create web pages/pages/blogs for those that are deceased, 
        share content of those that are deceased, connect users based on a relationship with someone that is deceased, give users 
        the ability to manage a page based 
        on a person that is deceased. You also agree not to duplicate any features that are unique to Lifeshare.</p>
  </div>
</template>

<script>
export default {
    name: 'terms'
}
</script>

<style>
    body {
        background-color: white;
    }
    .terms {
        background-color: white;
        padding: 2rem 4rem;
        text-align: center;
        max-width: 900px;
        margin: 0 auto;
    }
    p {
        text-align: center;
        line-height: 1.75rem;
    }
    ul {
        text-decoration: none;
        list-style: none;
    }
    li {
        margin: .5rem;
        text-align: left;
    }
</style>