// Copywrite Justin Roseman 2021
import CreateVaultChecker from '@/components/create-vault-checker'
import { mapGetters } from 'vuex'
export default {
  name: 'vault-list',
  props: ['managed', 'disabled', 'vaultList'],
  data() {
    return {
      selectedId: null,
    }
  },
  created() {
    this.vaultListCopy = this.vaultList
  },
  deactivated() {
    // this.vaultList = null
    this.selectedId = null
  },
  components: {
    CreateVaultChecker,
  },
  computed: {
    ...mapGetters({
      currentUser: 'getLoggedInUser',
    }),
    sortedVaultList() {
      return this.vaultList.sort((a, b) => {
        return a.firstname.localeCompare(b.firstname)
      })
    },
  },
  methods: {
    getAvatar(vaultId) {
      let avatar = 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?f=y'
      const vault = this.vaultList.find(vault => vault.id === vaultId)
      if (vault.avatar !== null) {
        avatar = vault.avatar
      }
      return avatar
    },
    selectManageOptions(vaultId) {
      if (this.selectedId === vaultId) this.selectedId = null
      else this.selectedId = vaultId
    },
    viewNewPosts(vaultId) {
      this.$emit('viewNewPosts', vaultId)
      this.selectedId = null
    },
    viewEvents(vaultId) {
      this.selectedId = null
      this.$emit('viewEvents', vaultId)
    },
    viewSettings(vaultId) {
      this.selectedId = null
      this.$emit('viewSettings', vaultId)
    },
  },
}
