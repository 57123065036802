let BASE_URL = 'http://localhost:4000'
if (window.location.hostname === 'localhost') BASE_URL = 'http://localhost:4000'
else {
  BASE_URL = 'https://ypo91gj2rb.execute-api.us-east-1.amazonaws.com/dev'
}
export default BASE_URL
export const USER_URL = `${BASE_URL}/lifeshare/v1/models/user`
export const VAULT_URL = `${BASE_URL}/lifeshare/v1/models/vault`
export const POST_URL = `${BASE_URL}/lifeshare/v1/models/post`
export const COMMENT_URL = `${BASE_URL}/lifeshare/v1/models/comment`
export const EVENT_URL = `${BASE_URL}/lifeshare/v1/models/event`
export const EVENT_LIST_URL = `${BASE_URL}/lifeshare/v1/models/event-list`
export const LIKE_URL = `${BASE_URL}/lifeshare/v1/models/like`
export const AUTH_URL = `${BASE_URL}/lifeshare/v1/auth`
export const STORAGE_URL = `${BASE_URL}/lifeshare/v1/storage`
