// Copywrite Justin Roseman 2021
import { postAPI } from '@/apis/apis'

export default {
  state: {
    vaultPosts: [],
  },
  getters: {},
  mutations: {},
  actions: {
    fetchAllPosts() {
      return new Promise((resolve, reject) => {
        postAPI
          .get('/')
          .then(res => {
            resolve(res.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchPosts(_, vaultId) {
      return new Promise((resolve, reject) => {
        postAPI.get(`/${vaultId}`).then(res => {
          resolve(res.data)
        })
      })
    },
    fetchPostById(id) {
      return new Promise(() => {
        postAPI.get(`/${id}`).then(res => console.log(res))
      })
    },
  },
}
